import './nettworks.css'
import { Header } from '../../components/Header'
import { Logo } from '../../components/Logo'
import { Input } from '../../components/Input'
import { MdAddLink } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../../services/firebaseConection'
import { toast } from 'react-toastify'


export default function Networks() {
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");
    const [youtube, setYoutube] = useState("");

    useEffect(() => {
        async function loadLinks() {
            const docRef = doc(db, "social", "link");
            await getDoc(docRef)
                .then((snapshot) => {
                    if (snapshot.data() !== undefined) {
                        setFacebook(snapshot.data().facebook);
                        setInstagram(snapshot.data().instagram);
                        setYoutube(snapshot.data().youtube);
                    }
                })
        }

        loadLinks();

    }, [])

    async function handleSave(e) {
        e.preventDefault();

        await setDoc(doc(db, "social", "link"), {
            facebook: facebook,
            instagram: instagram,
            youtube: youtube,
            dtInsert: new Date(),
        })
            .then(() => { toast.success("Dados gravados com sucesso"); })
            .catch((error) => { toast.error("Opss deu algum erro") })
    }

    return (
        <div className='admin-container'>
            <Header />
            <Logo />
            <h2 className='title-social'>Minhas Redes Sociais</h2>
            <form className="form" onSubmit={handleSave} >
                <label className="label">Link do Facebook</label>
                <Input placeholder="Digite a Url do Facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />

                <label className="label">Link do Instagram</label>
                <Input type="url" placeholder="Digite a Url do Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />

                <label className="label">Link do YouTube</label>
                <Input type="url" placeholder="Digite a Url do YouTube" value={youtube} onChange={(e) => setYoutube(e.target.value)} />

                <button className='btn-register' type='submit'><MdAddLink size={24} color="#fff" /> Salvar Links</button>
            </form>
        </div>
    )
}