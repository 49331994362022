import { Link } from 'react-router-dom'
import './logo.css'


export function Logo() {
    return (
        <Link to="/">
            <h1 className='logo'>Rogerio<span className='logo-text'>Lobo</span></h1>
        </Link>
    )

}