import './home.css'
import { Logo } from '../../components/Logo'
import { useEffect, useState } from 'react'
import { collection, getDocs, orderBy, query } from 'firebase/firestore'
import { db } from '../../services/firebaseConection'

export default function Home() {
    const [links, setLinks] = useState([]);
    useEffect(() => {
        function loadLinks() {
            const linksRef = collection(db, "links");
            const queryRef = query(linksRef, orderBy("dtInsert", "desc"));

            getDocs(queryRef)
                .then((snapshot) => {
                    let lista = [];

                    snapshot.forEach((doc) => {
                        lista.push({
                            id: doc.id,
                            name: doc.data().name,
                            url: doc.data().url,
                            bg: doc.data().bg,
                            color: doc.data().color
                        })
                        setLinks(lista);
                    })
                })

        }

        loadLinks();

    }, []);


    return (
        <div className="home-container">
            <Logo />
            <span>Veja meus Links  👇</span>

            <main className='links'>
                {links.map((item, index) => (
                    <section key={index} className='link-area' style={{ backgroundColor: item.bg }}>
                        <a href={item.url} target="blank">
                            <p className='link-text' style={{ color: item.color }}>{item.name}</p>
                        </a>
                    </section>
                ))}
            </main>

        </div>
    )
}