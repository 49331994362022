
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyD-xOvbA987vkuCqnr4VUGjC0DySGFVg2g",
    authDomain: "devlinks-1dcd2.firebaseapp.com",
    projectId: "devlinks-1dcd2",
    storageBucket: "devlinks-1dcd2.appspot.com",
    messagingSenderId: "327112778283",
    appId: "1:327112778283:web:3ee9684dd8ffe3d989406e",
    measurementId: "G-Y3HSEGENTC"
};

const firebaseapp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseapp);
const auth = getAuth(firebaseapp);

export { db, auth };